// src/hooks/useDefineTrip.ts

import { post } from "aws-amplify/api";
import { Dayjs } from "dayjs";
import { useState } from "react";
import { Day, Stop, Trip } from "../models/tripModels";
import { getPrompt } from "../utils/prompts";
import { generateTasks } from "../utils/tasks";
import { useNavigate } from "react-router-dom";

interface DaySimple {
  description: string;
}

interface StopSimple {
  name: string;
  accommodation: string;
  days: DaySimple[];
}

export const useDefineTrip = (
  setStops: (stops: Stop[]) => void,
  handleCreateTrip: (trip: Trip) => void,
  resetState: () => void
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const validateForm = (tripName: string, startDate: Dayjs | null) => {
    if (!tripName || !startDate) {
      setError("Please fill out trip name and start date");
      return false;
    }
    setError("");
    return true;
  };

  const getResponse = async (tripName: string, startDate: Dayjs, tripDescription: string) => {
    if (!validateForm(tripName, startDate)) return false;

    setIsLoading(true);

    try {
      const restOperation = await post({
        apiName: "openai",
        path: "/query",
        options: {
          body: {
            message: getPrompt(tripName, startDate, tripDescription),
          },
        },
      });

      const data = await restOperation.response;
      const body: any = await data.body.json();
      const response = body["response"]["choices"][0]["message"]["content"];
      const parsedStops = JSON.parse(response) as StopSimple[];
      const stops: Stop[] = parsedStops.map((s, sIdx) => {
        const days: Day[] = s.days.map((d, dIdx) => {
          const isLastDay = sIdx === parsedStops.length - 1 && dIdx === s.days.length - 1;
          return {
            description: d.description,
            accommodation: isLastDay ? "" : s.accommodation,
          };
        });

        return {
          name: s.name,
          days: days,
        };
      });

      const tasks = generateTasks(startDate, stops);

      const newTrip = {
        name: tripName,
        description: tripDescription,
        startDate: startDate!,
        stops: stops,
        tasks: tasks,
        deleted: false,
        shared: false,
      };

      const newId = await handleCreateTrip(newTrip);

      navigate(`/edit/new/${newId}`, { replace: true });

      resetState();

      console.log("POST call succeeded: ", parsedStops);
      return true;
    } catch (e) {
      console.log("POST call failed: ", e);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, error, setError, getResponse };
};
