/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createTrip = /* GraphQL */ `mutation CreateTrip(
  $input: CreateTripInput!
  $condition: ModelTripConditionInput
) {
  createTrip(input: $input, condition: $condition) {
    id
    name
    description
    startDate
    stops
    tasks
    deleted
    shared
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTripMutationVariables,
  APITypes.CreateTripMutation
>;
export const updateTrip = /* GraphQL */ `mutation UpdateTrip(
  $input: UpdateTripInput!
  $condition: ModelTripConditionInput
) {
  updateTrip(input: $input, condition: $condition) {
    id
    name
    description
    startDate
    stops
    tasks
    deleted
    shared
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTripMutationVariables,
  APITypes.UpdateTripMutation
>;
export const deleteTrip = /* GraphQL */ `mutation DeleteTrip(
  $input: DeleteTripInput!
  $condition: ModelTripConditionInput
) {
  deleteTrip(input: $input, condition: $condition) {
    id
    name
    description
    startDate
    stops
    tasks
    deleted
    shared
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTripMutationVariables,
  APITypes.DeleteTripMutation
>;
